.news-heading {
    display: inline-flex;
    margin: 150px 30px 0;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.news-heading h1 {
    margin: 0;
}

.inline-news-link {
    color: black;
    background: none;
    border: none;
    text-decoration: underline;
    font-size: 16px;
    vertical-align: bottom;
    white-space: nowrap;
    cursor: pointer;
}

.join-maillist {
    max-width: 600px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 20px;
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

.join-maillist.visible {
    max-height: 500px; /* Adjust as needed */
    opacity: 1;
}

.join-maillist.hidden {
    max-height: 0;
    opacity: 0;
}

.join-maillist > div {
    display: flex;
    gap: 20px;
}

.join-maillist span {
    margin-bottom: 10px;
}

.join-maillist button,
.join-maillist input {
    display: inline-block;
    width: auto;
    height: 50px;
}

.join-maillist input {
    flex-grow: 1;
    padding: 5px 15px;
    margin-bottom: 0;
}

.join-maillist button {
    margin: 0 10px 10px 0;
    padding: 10px 20px;
    background: #4046ca;
    border: none;
    color: white;
    font-family: "Roboto Slab", sans-serif;
    border-radius: 25px;
    text-transform: uppercase;
    font-weight: 900;
    vertical-align: top;
}

.news-header {
    max-width: 1440px;
    margin: 0 auto;
}

.newslist {
    padding: 0 30px;
    box-sizing: border-box;
    max-width: 1440px;
    margin: 0 auto;
}
@media only screen and (min-width: 768px) {
    .news-heading {
        margin-left: 50px;
    }
    .newslist {
        padding: 0 50px;
    }
    .join-maillist {
        margin-left: 50px;
        margin-right: 50px;
    }
}


@media only screen and (min-width: 1200px) {
    .news-heading {
        margin-left: 120px;
    }
    .newslist {
        padding: 0 120px;
    }
    .join-maillist {
        margin-left: 120px;
        margin-right: 120px;
    }
    .inline-news-link {
        padding-left: 0;
    }
}

.year-block {
    max-height: 60px;
    transition: 0.3s;
    overflow: hidden;
}

.year-block:first-child {
    margin-top: 40px;
}

.year-block:last-child {
    margin-bottom: 100px;
}

.opened-year.year-block {
    margin-top: 100px;
    margin-bottom: 40px;
    max-height: 100000px;
}

.year-block h2 {
    cursor: pointer;
    font-size: 30px;
    margin-bottom: 10px;
}

.month-block {
    pointer-events: none;
    max-height: 0;
    opacity: 0;
    transition: 0.3s;
    margin-bottom: 20px;
}
.opened-year .month-block {
    pointer-events: all;
    transition: 0.3s;
    max-height: 100000px;
    opacity:1;
}

.month-block h3 {
    font-size: 20px;
    margin-bottom: 15px;
}

.newslink {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.news-grid {
    transition: 0.5s;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 30px;
}

.news-grid>li {
    position: relative;
    list-style: none;
    padding: 20px;
    max-width: 700px;
    border-left: 2px solid black;
    height: 100%;
    transition: 0.5s;
}

.news-grid>li.opened {
    transition: 0.5s;
    grid-column: 1 / -1;
}
.news-grid>li.opened h4 {
    display:block;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 900;
}

.h4_input {
    width: 100%;
    font-size: 20px;
    padding: 10px;
    font-weight: 900;
    margin: 20px 0;
    border: none;
    display: block;
    border-left: 2px dashed #abafff;
    background: #a1a2c313;
    outline-color: #4046ca;
}

.news-grid h4 {
    transition: 0.5s;
    font-weight: normal;
}
.news-grid h4 span {
    font-weight: bold;
    margin-right: 10px;
}

.singlenews ul {
    margin: 10px 10px 10px 20px;
}


.news_back {
    height: 16px;
    width: 16px;
    display:block;
    margin: 0px 0 20px;
}