.toggle-container {
  display: flex;
  align-items: center;
}

.toggle-container input {
  display: none;
}

.toggle-slider {
  width: 40px;
  height: 20px;
  border: 2px solid black;
  background-color: white;
  border-radius: 20px;
  position: relative;
  transition: 0.2s;
  cursor: pointer;
  display:none;
}

.toggle-slider::before {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: black;
  top: 1.5px;
  left: 2px;
  transition: 0.2s;
}

input:checked + .toggle-slider {
  background-color: black;
}

input:checked + .toggle-slider::before {
  transform: translateX(19px);
  background-color: white;
}

.toggle-label {
  margin-left: 10px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 900;
  margin-right: -25px;
  transform: rotate(-90deg);
  transition: 0.2s;
}

@media only screen and (min-width: 450px) {
  .toggle-slider {
    display:inline;
  }
  .toggle-label {
    margin-right: 0;
    transform: rotate(0);
  }
  
}