.pill {
    font-weight: bold;
    padding: 0 5px 0 15px;
    color: black;
    border-radius: 20px;
    line-height: 0;
    vertical-align: top;
    display: inline-block;
    list-style: "none";
}

.pill img {
    height:22px;
    width: 22px;
    transform:scale(1.3);
    margin-right: 10px;
}

.pill.opened {
    display: inline-flex;
    align-items: center;
    gap: 13px;
    background:white;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    align-content: flex-start;
    height:auto;
}

.pill span {
    display:inline-block;
    vertical-align:top;
    margin-right: 5px;
    line-height: 25px;
}

.pill a {
    text-decoration: none;
    color:black;
    display: flex;
}

.pill div {
    display: flex;
    gap: 5px;
    height: 20px;
    align-items: center;
    align-content: flex-start;
    height:auto;
    flex-wrap: wrap;
}

.pill div a {
    border-radius: 12px;
    background: black;
    color: white;
    display: block;
    line-height: 14px;
    padding: 2px 10px;

}

.category_group {
    position: relative;
}

.category_group::before {
    content:"";
    position: absolute;
    height: calc(100% - 40px);
    top: 33px;
    left: 12px;
    z-index: -1;
    border-radius: 2px;
    border-right: 3px solid black;
}