#home-container {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  height: 100vh;
  padding: 50px 30px 0;
}

#home-container h1 {
  margin-left: 0;
  margin-bottom: 20px;
}

#home-container p,#home-container a {
  font-size: 20px;
  line-height: 1.5em;
}

#home-container .home-links {
  margin: 30px 0;
  text-transform: uppercase;
}

#home-container .part a {
  color: black;
}

#home-container .inline-link {
  color: black;
  text-decoration: none;
  margin-right: 30px;
  font-size: 25px;
  font-weight: 900;
  display: inline-block;
}

#home-container #graph {
  position:static;
  top: 100px;
  bottom: unset;
}
#home-container .loader {
  position:static;
}

.col {
  width: calc(50% - 50px);
}

.news {
  padding: 50px 30px 120px;
}

.news h2 {
  margin-bottom: 40px;
  color: black;
}

.news ul {
  gap: 50px;
  display:flex;
  flex-wrap: wrap;  
}

.news li {
  list-style: none;
}

.news li a {
  text-decoration: 0;
  color: black;
}

.news li a h3 {
  font-weight: normal;
  font-size: 20px;
}
.news li span {
  font-weight: bold;
  margin-right: 10px;
}

@media only screen and (min-width: 720px){
  #home-container {
    padding: 50px 50px 120px;
  }
  
  .news {
    padding: 50px 50px 120px;
  }
 
}

@media only screen and (min-width: 1200px){
  #home-container {
    justify-content: space-between;
    width: calc(100% - 100px);
    padding: 0 50px 0 110px;
    margin: 0 auto;
  }
  
  .news {
    width: calc(100% - 100px);
    padding: 0 50px 150px 110px;
    margin: 0 auto;
  }
  .news ul li {
    max-width: 250px
  }
  
}