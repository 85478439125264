footer {
    background: black;
    color: white;
    position: relative;
    z-index: 5;
}

footer>div {
    padding: 50px 50px 120px;
    display: flex;
    flex-wrap: wrap;
    max-width: 1440px;
    margin: 0 auto;    
    gap:50px;
}
footer button,
footer a {
    text-decoration: underline;
    background:transparent;
    cursor:pointer;
    border:none;
    font-size: 16px;
    display: block;
    color: white;
    margin-bottom: 10px;
}
footer .socials a {
    display:inline-block;
}

@media only screen and (min-width: 1200px){
    footer>div {
        padding: 50px 140px 120px;
        margin: 0 auto;
    }
}