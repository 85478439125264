.power-intro h1 {
    margin: 100px 0 20px 0;
    font-size: 32px;
}

.power-intro {
    padding-bottom: 30px;
    margin: 0 auto;
    max-width: 1440px;
}

.power-intro .formattedText {
    columns: 2;
    column-width: 300px;
    column-gap: 40px;
}
.power-intro .full_width .formattedText {
    columns: 1;
    column-width: unset;
}

.power-job-card {
    background: #3939FF;
    margin: 20px 10px;
    padding: 50px;
    border-radius: 10px;
    color: white;
    position: relative;
    overflow: hidden;
    padding-bottom: 400px;
}

.power-job-card::before {
    content: '';
    border: 2px solid white;
    border-radius: 5px;
    position: absolute;
    left: 20px;
    top: 20px;
    bottom: 20px;
    right: 20px;
    z-index: 1;
    pointer-events: none;
}

.power-job-card .pattern {
    position: absolute;
    right: 0;
    left:0;
    bottom: -100px;
    max-width: 120%;
    max-height: 100%;
    transform: rotate(90deg);
}

.power-job-card .section{ 
    position: static;
}

.power-job-card .section p img {
    position: absolute;
    height: 90%;
    margin: 0;
    bottom: 0;
    top: unset;
    right: 50px;
    width: auto;
    display: inline-block;
    z-index: 2;
    max-height: 380px;
    transform: rotate(0);
}

.power-job-card h2 {
    max-width: 600px;
    font-size: 20px;
    margin-bottom: 20px;
    text-transform: none;
}

.power-media {
    margin: 100px 30px;
}
.power-media p {
    max-width: 750px;
}
.power-media .slick-slider .slick-slide p img {
    width: 100%;
    height: unset;
}

.power-media .slick-slide {
    padding: 10px;
}

.power-media .slick-slider .slick-prev {
    left: -20px;
}
.power-media .slick-slider .slick-next {
    right: -20px;
}


.power-job-card p {
    max-width: 600px;
    white-space: pre-wrap;
}

.power-job-card a {
    margin-top: 20px;
    margin-right: 20px;
    background: none;
    border: 2px solid white;
    color: white;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 15px;
    display: inline-block;
    text-decoration: none;
}

.power-full-width {
    background: #3939FF;
    position: relative;
    color: white;
    overflow: hidden;
    padding: 80px 30px 80px 30px;
}

.power-full-width .section {
    flex: 1 1 360px;
}

.no-padding {
    padding:0;
    margin:0;
}

.videos {
    display: flex;
    gap: 40px;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 40px;
}

.videos .part {
    max-width: 360px;
    margin-top: 0;
}
.videos .part p {
    margin-bottom: 0;
}
.power-logod {
    margin: 100px 0 150px;
}
.power-logod h2,
.power-logod p {
    display: flex;
    flex-wrap:wrap;
    gap: 40px;
    margin: 10px 30px;
    max-width: 1000px;
    align-items: center;
}
.power-logod img{
    max-width: 200px;
    object-fit: contain;
    width: unset;
    height: unset;
}

@media only screen and (min-width: 720px){
    .power-job-card {
        padding: 80px;
        margin: 30px;
    }
    .power-job-card h2,
    .power-job-card p {
        width: 50%;
    }
    .power-job-card .pattern {
        left:unset;
        top:0;
        bottom: 0;
        max-height: 700px;
        transform: rotate(0);
    }
}

@media only screen and (min-width: 1200px){
    .power-intro {
        padding: 20px 90px 0 140px;
    }
    .power-intro h1 {
        font-size: 40px;
    }
    .power-full-width {
        padding: 100px 100px 100px 140px;
    }
    .power-job-card h2 {
        font-size: 25px;
    }
    .power-job-card h2,
    .power-job-card p {
        width: 70%;
    }
    .power-media {
        margin: 100px 50px 100px 140px;
    }
    .power-logod h2,
    .power-logod p {
        margin: 10px 50px 10px 140px;
    }
    .power-job-card {        
        margin: 50px 50px 50px 120px;
    }
}

@media only screen and (min-width: 1600px){
    .power-intro {
        padding: 0;
    }
    .power-media,
    .power-logod,
    .power-job-card {
        margin: 50px auto;
        max-width: 1440px;
    }
    .power-logod h2,
    .power-logod p {
        margin: 100px 0;
    }
    .power-full-width>div {
        margin: 50px auto;
        max-width: 1440px;
    }
    
}

