.loginform {
    margin-top: 200px;
    margin-left: 50px;
    width: 100%;
    max-width: 500px;
}
@media only screen and (min-width: 1200px) {
    .loginform {
        margin-left: 90px;
    }    
}


.loginform button {
    margin: 10px 10px 10px 0;
    padding: 10px 20px;
    background: #4046ca;
    border:none;
    color:white;
    font-family: "Roboto Slab", sans-serif;
    border-radius: 25px; 
    text-transform: uppercase;
    font-weight: 900;
}

.loginform .orange {
    background:#f68511;
}


.loginform input:disabled {
    color: #4047ca6f;
}
 
.loginform label {
    color: #4046ca;
    font-weight: 900;
    font-size: 12px;
}

.forgotpass {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
    color:#4046ca;
    margin-left: 20px;
}