.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    padding: 20px;
    border-radius: 8px;
    position: relative;
}

.modal-close {
    position: absolute;
    top: -10px;
    right: 20px;
    font-weight: bold;
    color: white;
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
}

.modal-content video {
    max-height: 90vh;
    max-width: 95vw;
}