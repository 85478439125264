.header-container {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    background: white;
    z-index: 2;
    padding: 40px 50px 30px 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 30px;
}
.header-container::before {
    content: '';
    position: absolute;
    pointer-events: none;
    bottom: -100px;
    left: 0;
    width: 1100px;
    height: 100px; /* Adjust height of shadow */
    background: radial-gradient(circle at 250px -550px,
        rgba(0, 0, 0, 0.15) 30%, 
        rgba(0, 0, 0, 0.07) 45%, 
        rgba(255, 255, 255, 0) 60%);
}

.header-container h1 {
    color: black;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    vertical-align: bottom;
    line-height: 30px;
    transition: 0.5s;
    margin: 0;
}

.header-container .headers {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.header-container .active {
    font-size: 40px;
}

.header-dropdown {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.header-dropdown .chevron {
    font-size: 30px;
    vertical-align: top;
}

.dropdown-content {
    display: flex;
    flex-direction: column;
    background: white;
    position: absolute;
    top: 100px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: 0.5s;
    max-height: 500px; 
    overflow: hidden;
}

.dropdown-content a {
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
}

.dropdown-content a:last-child {
    border-bottom: none;
}

#filters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

}

#filters button {
    font-weight: bold;
    border-radius: 20px;
    border: 2px solid black;
    padding: 2px 12.5px 2px 17.5px;
    background: black;
    color: white;
    cursor: pointer;
    transition: 0.2s;
    font-size: 16px;
    display:flex;
    flex-wrap: nowrap;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflow content */
}
#filters button p {
    margin-right: 10px;
    margin-bottom: 0;
    white-space: nowrap;
}

#filters button span {
    margin-left: 5px;
    transition: 0.2s;
    display:inline-block;
    opacity: 0;
    width: 0;
}
#filters button:hover span {
    margin-right: 0;
    opacity: 1;
    width: 10px;
    transform:scale(1);
}
#filters button:hover {
    padding: 2px 10px 2px 10px;
}

@media only screen and (max-width: 720px) {
    #filters button span {
        margin-right: 0;
        opacity: 1;
        width: 10px;
        transform:scale(1);
    }
    #filters button {
        padding: 2px 10px 2px 10px;
    }
    .header-container {
        gap: 20px;
    }
    .header-container .active {
        font-size: 25px;
    }
}

.card-container {
    transition: 0.5s;
    margin-top: 120px;
    padding: 50px 20px;
}
.filterPanel {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: row;
    gap:60px;
    transition: 0.2s;
    align-items: flex-start;    
    z-index: 4;
    border-left: 3px solid black;
    padding: 120px 30px 30px;
    background: white;
}

.filterPanel.hidden {
    right: -120%;
}
    
.graphPanel {
    position: fixed;
    height: min(calc(100vw - 120px), calc(100vh - 120px));
    width: min(calc(100vw - 120px), calc(100vh - 120px));
    bottom: 50px;
    background:white;
    max-width: 80%;
    transition: 0.5s;
    z-index: 3;
    border-top: 3px solid linear-gradient(to right, black 0%, black 70%, white 100%);;
    border-left: 3px solid black;
    display: flex;
}

.graphPanel.hidden {
    right: -120%;
}

.toggles {
    position: fixed;
    top: 45px;
    right: 30px;
    z-index: 9;
    display: flex;
    gap: 40px;
    transition: 0.5s;
}

@media only screen and (min-width: 720px) and (min-height: 720px) {
    .filterPanel {
        flex-direction: row;
        background: 0;
        border: none;
        background: none;
        flex-direction: column;
        justify-content: center;
        padding: 10px 50px 0;
        gap: 20px;
    }
    .toggles {
        right: 150px;
    } 
}
@media only screen and (min-width: 1200px) {
    .header-container {
        padding: 40px 0 20px 90px;
    }
    .card-container {
        padding: 50px 20px 50px 90px;
    }

    .graphPanel {
        border:none;
        background: none;
        max-width:50%;
    }
}
  
