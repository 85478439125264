h1#jobs {
    padding: 30px;
    margin: 0;
}

.managers {
    padding: 0 30px 140px; 
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: flex-end;
    max-width: 1440px;
    margin: 0 auto;
}
.managers a {
    color: black;
}


@media only screen and (min-width: 1200px){
    .managers {
        padding: 0 140px 120px; 
    }
    h1#jobs {
        padding: 120px 140px 0;
    }
    
}

.managers h3 {
    text-transform: uppercase;
}

.manager {
    display: inline-block;
    text-align: center;
    max-width: 235px;
    flex-basis: 210px;
}

.managers>p {
    padding-top: 30px;
}

.manager img {
    height: 160px;
    width: 160px;
    margin: 0;
}