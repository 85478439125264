.principles {
    position: relative;
    color: white;
    overflow: hidden;
    padding: 80px 50px 80px 30px;
}
.introduction {
    padding: 20px 50px 100px 30px; 
}

.about-title {
    margin: 50px 50px 0 30px;
}

.about-subtitle {
    margin: 100px 50px 30px 30px;
}

.principles h3 {
    margin-top: 20px;
    margin-bottom: 10px
}
.principles .hoverBtn {
    border:white solid 2px;
}
.principles .hoverBtn:nth-child(2) {
    border:none;
}

.principles>.part {
    z-index: 1;
}

.principles .overlayBtn:hover {
    background:#ffffff0f;
    border: 2px dotted #ffffff; 
}   

.colored-background {
    background: #4046CA;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
}

.outer {
    position: absolute;
    height: 700px;
    width: 700px;
    background: #272A7B;
    opacity: 40%;
    border-radius: 50%;
    transform:translate(-50%, -50%);
}
.inner {
    position: absolute;
    height: 500px;
    width: 500px;
    background: #554CB9;
    opacity: 50%;
    border-radius: 50%;
    transform:translate(-50%, -50%);
}


#valdkonnad {
    padding: 0;
}

#teenused {
    padding: 0 0 100px 0;
}

@media only screen and (min-width: 1200px){
    .about-subtitle {
        margin: 100px 50px 30px 30px;
    }    
    .principles {
        padding: 150px 100px 150px 140px;
    }
    .principles h3 {
        margin-bottom: 30px
    }

    .about-title::before {
        content: "";
        display: block;
        height: 200px;
        margin-top: -200px;
      }
    .about-title {
        margin: 200px 50px 0 140px;
    }
    .introduction {
        padding: 20px 90px 100px 140px;
    }
    #valdkonnad {
        padding: 20px 50px 0 90px;
    }
    #teenused {
        padding: 20px 50px 150px 90px;
    }
  }