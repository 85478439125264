
#graph {
  max-width: calc(min(100vw,100vh) - 150px);
  max-height: calc(min(100vw,100vh) - 150px);
  box-sizing:border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  transition: 0.3s;
  transition-delay: 0.3s;
}

@media only screen and (min-width: 1200px) {
}

#tooltip {
  position: absolute;
  background-color: white;
  border: 2.5px solid black;
  text-align:center;
  font-size: 20px;
  font-weight: bold;
  border-radius: 10px;
  box-sizing: border-box;
  max-width: 350px; 
}

#tooltip-info {
  display: block;
}

#tooltip-title {
  text-align: center;
  display: block;
  min-width: 200px;
}


#tooltip-extra {
  text-align: center;
  display: inline-block;
}
  
#tooltip-body {
  text-align: center;
  display: inline-block;
}

.voronoi.is_link {
  cursor:pointer;

}

/* .deselect-btn {
  position: absolute;
  right: 100px;
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  top: 60px;
  border: 2px solid black;
  color: black;
  font-size: 20px;
  line-height: 20px;
  padding: 0;
  margin: 0;
  background: white;
}

 */
.loader {
  width: 200px;
  height: 200px;
  max-width: calc(min(100vw,100vh) - 150px);
  box-sizing:border-box;
  position: absolute;
  bottom: calc(50vh - 100px);
  left: calc(25vw - 100px);
  z-index: 3;
  margin: 0 auto;
  animation: rotate calc(2.5s * 4) linear infinite;
}

@keyframes orbit {
  0% {transform: translate(calc(200px * 0.5)) scale(0.73684);opacity: 0.65;}
  5% {transform: translate(calc(200px * 0.4)) scale(0.684208);opacity: 0.58;}
  10% {transform: translate(calc(200px * 0.3)) scale(0.631576);opacity: 0.51;}
  15% {transform: translate(calc(200px * 0.2)) scale(0.578944);opacity: 0.44;}
  20% {transform: translate(calc(200px * 0.1)) scale(0.526312);opacity: 0.37;}
  25% {transform: translate(0%) scale(0.47368);opacity: 0.3;}
  30% {transform: translate(calc(200px * -0.1)) scale(0.526312);opacity: 0.37;}
  35% {transform: translate(calc(200px * -0.2)) scale(0.578944);opacity: 0.44;}
  40% {transform: translate(calc(200px * -0.3)) scale(0.631576);opacity: 0.51;}
  45% {transform: translate(calc(200px * -0.4)) scale(0.684208);opacity: 0.58;}
  50% {transform: translate(calc(200px * -0.5)) scale(0.73684);opacity: 0.65;}
  55% {transform: translate(calc(200px * -0.4)) scale(0.789472);opacity: 0.72;}
  60% {transform: translate(calc(200px * -0.3)) scale(0.842104);opacity: 0.79;}
  65% {transform: translate(calc(200px * -0.2)) scale(0.894736);opacity: 0.86;}
  70% {transform: translate(calc(200px * -0.1)) scale(0.947368);opacity: 0.93;}
  75% {transform: translate(0%) scale(1);opacity: 1;}
  80% {transform: translate(calc(200px * 0.1)) scale(0.947368);opacity: 0.93;}
  85% {transform: translate(calc(200px * 0.2)) scale(0.894736);opacity: 0.86;}
  90% {transform: translate(calc(200px * 0.3)) scale(0.842104);opacity: 0.79;}
  95% {transform: translate(calc(200px * 0.4)) scale(0.789472);opacity: 0.72;}
  100% {transform: translate(calc(200px * 0.5)) scale(0.73684);opacity: 0.65;}
}

@keyframes rotate {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

.particle {
  position: absolute;
  top: 0%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.particle:nth-child(1) {--uib-delay: 0;transform: rotate(8deg);}
.particle:nth-child(2) {--uib-delay: -0.4;transform: rotate(36deg);}
.particle:nth-child(3) {--uib-delay: -0.9;transform: rotate(72deg);}
.particle:nth-child(4) {--uib-delay: -0.5;transform: rotate(90deg);}
.particle:nth-child(5) {--uib-delay: -0.3;transform: rotate(144deg);}
.particle:nth-child(6) {--uib-delay: -0.2;transform: rotate(180deg);}
.particle:nth-child(7) {--uib-delay: -0.6;transform: rotate(216deg)}
.particle:nth-child(8) {--uib-delay: -0.7;transform: rotate(252deg);}
.particle:nth-child(9) {--uib-delay: -0.1;transform: rotate(300deg);}
.particle:nth-child(10) {--uib-delay: -0.8;transform: rotate(324deg);}
.particle:nth-child(11) {--uib-delay: -1.2;transform: rotate(335deg);}
.particle:nth-child(12) {--uib-delay: -0.5;transform: rotate(290deg);}
.particle:nth-child(13) {--uib-delay: -0.2;transform: rotate(240deg);}

.particle::before {
  content: '';
  position: absolute;
  height: 17.5%;
  width: 17.5%;
  border-radius: 50%;
  background-color: black;
  flex-shrink: 0;
  transition: background-color 0.3s ease;
  animation: orbit 2.5s linear calc(var(--uib-delay) * 2.5s) infinite;
}
