.peoplelist {
    list-style: none;
}
.peoplelist li {
    transition: 0.3s;
    transition-delay: 0.3s;
    max-width: 900px;
    position: relative;
    display:block;
    padding-bottom: 40px;
}
.peoplelist .collapsed {
    display:inline-block;
    transition: 0.3s;
    vertical-align: top;
    min-width: unset;
    max-width: 200px;
    transition-delay: 0.3s;
    padding-bottom: 0;
    border-left: 0 solid transparent;
}

.backbutton {
    position:absolute;
    right: 20px;
    top: 20px;
    display:block;
    height: 20px;
    width: 20px;
    
}
.personlink {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.peoplelist li a {
    color: black;
    padding-bottom: 20px;
    display:inline-block;
}
.peoplelist .collapsed > * {
    text-align: center;
    color: black;
}

.profile_img {
    transition: 0.3s;
    transition-delay: 0.3s;
    border: 2px solid black;
    height: 240px;
    width: 240px;
    border-radius: 120px;
    margin: 10px;
    vertical-align: middle;
    object-fit: cover;
}

.collapsed .profile_img {
    height: 180px;
    width: 180px;
}

.peoplelist li h2 {
    font-size: 16px;
    margin-bottom: 5px;
    max-width: 230px;
}


.person {
    transition: 0.3s;
    transition-delay: 0.3s;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}
.personheader {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
}
.card {
    vertical-align: middle;
    display: inline-block;
    padding-left: 20px;
    max-width: 260px;
}
.card p, .card a, .card {
    margin-bottom: 5px;
    transition: 0.2s;
    max-height: 200px;
    opacity: 1;
    pointer-events: all;
} 

.osakonnad, .show_projects_people {
    font-weight: bold;
}

.collapsed .card .osakonnad,
.collapsed .card a {
    max-height: 0;
    opacity: 0;
    pointer-events: none;
}

.show_projects_people {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.peoplelist li .show_projects_people * {
    flex-shrink: 0;
}
.peoplelist li .show_projects_people a {
    color: white;
    background: black;
    border-radius: 15px;
    padding: 2px 15px;
    height: 24px;
    flex-shrink: 0;
    text-decoration: none;
}

.collapsed .show_projects_people {
    opacity: 0;
    pointer-events: none;
    max-height: 0;
}

.collapsed .card {
    padding-left: 0;
    margin: 0 auto;
    display: block;
}

.person h1 {
    margin: 0;
    text-transform: none;
}

.osakond {
    margin-top:50px;
    margin-bottom: 50px;
}

.osakond>h2 {
    margin-left: 20px;
}
