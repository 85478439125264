.not_found {
    margin-top: 50px;
    padding: 20px;
}
.not_found h1 {
    margin: 0;
    margin-bottom: 20px;
    padding: 0;
}

.open_search {
    border: none;
    background: none;
    color: #4046CA;
    font-size: 16px;
    display: block;
    line-height: 20px;
    margin-bottom: 10px;
    text-decoration: underline;
}

@media only screen and (min-width: 500px) {
    .not_found{
        padding: 40px;
    } 
} 

@media only screen and (min-width: 1200px) {
    .not_found {
        padding: 50px 50px 50px 90px;
    }
} 