.privacy_page {
    padding: 50px 30px 120px;
}
.privacy_page h1 {
    margin: 150px 0 20px;
}

@media only screen and (min-width: 720px){
    .privacy_page {
      padding: 50px 50px 120px;
    }
}
  
@media only screen and (min-width: 1200px){
    .privacy_page {
      width: calc(100% - 100px);
      padding: 0 50px 120px 110px;
      margin: 0 auto;
    }
}