*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body,input,textarea {
  cursor: auto;
  font-family: 'Roboto Slab',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color:#4046CA;
}

a.skip-main {
  left:-999px;
  position:absolute;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  z-index:-999;
}
a.skip-main:focus, a.skip-main:active {
color: #fff;
background-color:#000;
left: auto;
top: auto;
width: 30%;
height: auto;
overflow:auto;
margin: 10px 35%;
padding:5px;
border-radius: 15px;
text-align:center;
font-size:1.2em;
z-index:999;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}

hr {
  border: none;
  padding: 10px;
}

h1 {
  font-size: 40px;
  flex-basis: 100%;
  font-weight: 900;
  margin: 100px 0 0 50px;
  text-transform: uppercase;
}

input {
  width: 100%;
  font-size: 16px;
  border: none;
  margin-bottom: 20px;
  height: 40px;
  padding: 10px;
  border-left: 2px dashed #abafff;
  background: #a1a2c313;
  outline-color: #4046ca;
}

@media only screen and (min-width: 1200px) {
  h1 {
      margin: 100px 0 0 90px;
  }
} 
