.headers {
    margin: 0 10px;
}
.headers .part {
    display: inline-block;
    margin-right: 20px;
    margin-top: 0;
}
.headers h3{
    border: 2px solid transparent;
    padding: 10px 20px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    margin: 0;
}
.headers .active-header {
    border-color: black;
}

.swiper-slide .part {
    margin-top: 0;
}
#valdkonnad a {
    color: white;
    background: black;
    border-radius: 20px;
    padding: 5px 15px;
    text-decoration: none;
}



.service {
    display:flex;
    margin-top: 20px;
}
.service.swiper-padding {
    padding: 0 30px;
}

.title {
    cursor: pointer;
    border: none;
    transition: 0.4s;
  }
  
.panel {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    border-left: 2px solid black;
    margin: 10px 0 10px 10px;
    transition: max-height 0.2s ease-out;
}

.swiper_project {
    border: 2px solid black;
    border-radius: 10px;
    padding: 40px 20px 20px;
    text-align: right;
    position: relative;
    font-weight: 700;
    text-decoration: none;
    color: black;
    display: block;
    margin-bottom: 50px;

}
.slick-slider .people {
    position: absolute;
    top: -5px;
    left: 40px;
    width: unset;
}

.slick-slider .people .avatar,
.slick-slider .people .number-circle{
    vertical-align: top;
    display: inline-block;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    border: 2px solid black;
    z-index: 1;
    margin-left: -20px;
}

.swiper_years {
    position: absolute;
    bottom: -15px;
    padding: 5px;
    right: 20px;
    background: white;
}
.methods {
    margin-top: 0;
    margin-bottom: 20px;
}

.methods .open p {
    max-height:unset;
    pointer-events:all;
    margin-bottom: 20px;
}
.methods .open h4 {
    margin-bottom: 5px;
}
.methods .closed p {
    transition: 0.5s;
    max-height: 0;
    overflow:hidden;
    pointer-events: none;    
}

.methods h4::before {
    content: "→"; /* '\25B6'; */
    font-size: 1em;
    font-family: 'slick';
    color: black;
    background: transparent;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    box-sizing: border-box;
    padding-left: 1px;
    margin-top:-4px;
    padding-top: 2px;
    margin-right: 10px;
    transition: transform 0.3s ease;
    display: inline-block;
    vertical-align: middle;
  }
  .methods h4:hover::before {
    height: 20px;
    width: 20px;
    margin-top:-5px;
  }
  
  .methods .open h4::before {
    transform: rotate(90deg); 
  }


.slick-slider .slick-slide img {
    display: inline-block;
    width: 40px;
    height: 40px;    
}

.slick-slider .slick-prev,
.slick-slider .slick-next {
    padding: 0;
    z-index: 1;
    border-radius: 50%;
}
.slick-slider .slick-prev:hover,
.slick-slider .slick-prev:focus,
.slick-slider .slick-next:hover,
.slick-slider .slick-next:focus {
    outline: none;
}
.slick-slider .slick-prev:hover:before,
.slick-slider .slick-prev:focus:before,
.slick-slider .slick-next:hover:before,
.slick-slider .slick-next:focus:before {
    font-size: 60px;
    line-height: 5px;
}   
.slick-slider .slick-prev:before,
.slick-slider .slick-next:before {
    color: black;
    opacity: 1;
    content: '›';
    font-size: 50px;
    line-height: 4px;
    font-family: "Roboto Slab", sans-serif;
}
.slick-slider .slick-prev:before {
    content: '‹';
}

.slick-slider .slick-prev{
    left: 10px;
}
.slick-slider .slick-next {
    right: 10px;
}

.with_cols {
    columns: 1;
    column-gap:20px;
    padding: 0 50px 0 30px;
}

@media only screen and (min-width: 1200px){
    .slick-slider .slick-prev{
        left: 0;
    }
    .slick-slider .slick-next {
        right: 0;
    }
    .with_cols {
        columns: 3;
    }
}