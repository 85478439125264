
::-moz-selection, 
::selection {
  background: #4046CA;
}

header {
  height: 60px;
  width: 100vw;
  z-index: 11;
  background: black;
  position: fixed;
  border-top: 2px solid white;
  bottom: env(safe-area-inset-bottom, 0);
  display: flex;
  cursor: pointer;
}


#logo {
  margin: 15px;
  max-height: 30px;
  max-width: 30px;
}
header span {
  color: white;
  font-weight: 700;
  vertical-align: top;
  line-height: 60px;
  white-space:nowrap;
  font-size: 20px;
  padding: 0 20px;
}
header .menutoggle {
  display: none;
}

@media only screen and (max-width: 720px) {
  header.opened .title {
    display: none;
  }
  header.opened .menutoggle {
    display: initial;
  }  
}

main {
  min-height: 100vh;
  box-sizing: border-box;
}

#search {
  background: black;
  position: fixed;
  border-radius: 50%;
  transition: 0.2s;
  height: 40px;
  width: 40px;
  padding: 10px;
  bottom: 10px;
  right: 10px;
  z-index: 11;
}
#language:hover,
#language:focus,
#search:hover,
#search:focus {
  transform: scale(1.3);
}


#language {
  color:white;
  position: fixed;
  border-radius: 50%;
  font-weight: bold;
  text-decoration: none;
  transition: 0.2s;
  height: 40px;
  width: 40px;
  padding: 10px;
  bottom: 10px;
  right: 60px;
  z-index: 11;
}

#search svg {
  fill: white;
}

#searchbar {
  background: black;
  position: fixed;
  top: 0;
  max-width: 1000px;
  width: 95%;
  border-radius: 0 0 20px 20px;
  padding: 20px;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
  transition: 0.2s;
}

.consent {
  position: fixed;
  background: white;
  z-index: 6;
  padding: 25px;
  border: 2px solid black;
  border-radius: 20px;
  width: 300px;
  right: 30px;
  margin-bottom: 30px;
  text-align: right;
}


.consent-text {
  margin: 0 10px 10px 15px;
}

.consent-btn {
  padding: 10px 20px;
  background: #4046ca;
  border:2px solid black;
  color:white;
  font-family: "Roboto Slab", sans-serif;
  border-radius: 25px; 
  text-transform: uppercase;
  font-weight: 900;
}
.decline-btn {
  margin: 10px 10px 0 0;
  padding: 10px 20px;
  background:white;
  border:2px solid black;
  font-family: "Roboto Slab", sans-serif;
  border-radius: 25px; 
  text-transform: uppercase;
  font-weight: 900;
}

.closed #searchbar {
  top: -100%;
}

#searchbar form {
  display:flex;
  margin-top: 10px;
}

#searchbar label {
  padding: 0; 
  color: white;
  margin: 0 0 20px 0;
}

#search_phrase {
  margin: 0;
  border: none;
  border-radius: 10px;
  background: white;
  outline-color: black;
}

#searchbar button {
  height: 40px;
  width: 40px;
  padding: 10px;
  margin-left: 10px;
  background: white;
  border-radius: 10px;
  border:none;
}

#searchbar button svg {
  height: 20px;
  width: 20px;
}

.search-results {
  max-height: 60vh;
  overflow:auto;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}


.search-results {
  --sb-track-color: #000000;
  --sb-thumb-color: #ffffff;
  --sb-size: 12px;
}

.search-results::-webkit-scrollbar {
  width: var(--sb-size)
}

.search-results::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 12px;
}

.search-results::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 12px;
  
}

@supports not selector(::-webkit-scrollbar) {
  .search-results {
    scrollbar-color: var(--sb-thumb-color)
                     var(--sb-track-color);
  }
}

.search-results h3 {
  color:#ffffff;
  margin-bottom: 10px;
}

.search-results div {
  padding: 0 5px;
  margin-top: 50px;
}


.search-results ul {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.search-results a {
  color: white;
  text-decoration: none;
}

.category-result {
  display: inline-block;
  padding: 2px 20px;
  border: 2px solid white;
  border-radius: 20px;
}

.search-results .project-result {
  display: inline-block;
  min-width: 220px;
  max-width: 260px;
  border-radius: 10px;
  background: white;
  padding:0;
}
.project-result a {
  color: black;
  display: block;
  width: 100%;
  height:100%;
  padding: 10px;   
}


.person-result {
  align-items: center;
  display: flex;
  text-align: center;
}

.person-result img {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  object-fit: cover;
}
#news-results ul {
  flex-basis: 100%;
}

.news-result {
  flex-basis: 100%;
  display: block;
  padding: 2px 20px;
  border-left: 2px solid white;

}

.bg-close {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  position: fixed;
  pointer-events: all;
}

.closed .bg-close {
  pointer-events: none;
}

.search-results li {
  color: white;
  list-style: none;
  padding: 10px 10px;
  font-weight: bold;
}
@media only screen and (min-width: 1300px) {
  #searchbar {
    width: 80%;
  }
}


@media only screen and (min-width: 1200px) {
  header {
    height: 100vh;
    width: 60px;
    justify-content: flex-start;
    flex-direction: column-reverse;
    border-top: none;
  }
  header span {
    transform: rotate(-90deg);
  }
  header::after {
    content: '›';
    position: fixed;
    top: 20px;
    left: 25px;
    transform: translateY(-50%);
    transform-origin: 50% 60%;
    transition: 0.2s;
    color: white;
    font-size: 50px;
    z-index: 11;
    pointer-events: none
  }
  header:hover::after,
  header:focus::after {
    transform-origin: 50% 60%;
    transform: translateY(-50%) scale(1.3);
  }
  
  header.opened:hover::after {
    transform-origin: 50% 60%;
    transform: translateY(-50%) scale(1.3) rotate(180deg);
  }
  #search {
    top: 50px;
    left: 10px;
    bottom: unset;
    right: unset;
  }
  #language {
    top: 90px;
    left: 10px;
    bottom: unset;
    right: unset;
  }
}

