.sidepanel {
    position: fixed;
    overflow:hidden;
    height: 100vh;
    left: 0;
    top: 0;
    bottom: 0;
    width: 200%;
    z-index: 10;
    display: flex;
    pointer-events: none;
    transition: 0.4s;
}
.opened.sidepanel {
    pointer-events: all;
}
.info.sidepanel {
    left: -100%;
}
nav,
.information {
    width: 100%;
    bottom: 60px;
    z-index: 2;
    background: black;
    color: white;
    display: flex;
    transition: 0.3s;
    cursor: url("/src/cursor_close.png"), auto;
}

nav {
    font-weight:700;
    padding: 20px 50px 20px 20px;
    text-transform: uppercase;
    font-size: 20px;
    left: 0;
    transform:translateX(-100%);
}

.information {
    padding: 50px 30px 20px;
    flex-direction: column;
    right: 0;
    text-align:right;
    justify-content: flex-start;
    font-size: 18px;
    gap:20px;
    transform:translateX(100%);
}

.information h2 {
    font-size: 20px;
    font-weight: 700;
}

.opened nav,.opened .information {
    transform: translatex(0);
}
.two_in_row {
    display: flex;
    gap:50px;
    justify-content: flex-end;
}
.label {
    font-weight:700;
    color:#F68511;
}
.requisites {
    margin-top: 30px;
}
.requisites>div {
    margin-top: 20px;    
}

.information .part{
    margin: 0;
}

@media only screen and (min-width: 720px) {
    .sidepanel {
        width: 100%;
        display: block;
    }
    .overlay {
        background: rgba(0, 0, 0, 0.1);
        -webkit-backdrop-filter: blur(0.3rem);
        backdrop-filter: blur(0.3rem);
        position: absolute;
        opacity: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: 0.2s;
    
    }
    .opened .overlay {
        cursor: url("/src/cursor_close.png"), auto;
        opacity: 1;
    }
    nav,
    .information {
        position: fixed;
        top: 0;
        max-width: 360px;
    }

}

@media only screen and (min-width: 1200px) {
    nav {
        bottom: 0;
        left: 60px;
    }
    .information {
        bottom: 0;
        right: 0;
    }
    
}

nav li,nav span {
    list-style-type: none;
    margin: 40px 0 0;
}
.subitem {
    margin: 15px 0 0;
    color:#F68511;
}
.subitem a{
    color: #F68511;
}


.information .section {
    flex: none;
}

.information a, nav a {
    color: white;
}

.information p {
    margin-bottom: 5px;
}

nav a {
    text-decoration: none;
}

.locations p {
    display:flex;
    justify-content: flex-end;
}

.location {
    display: inline-block;
    margin-left: 20px;
    max-width: 44%;
    vertical-align: top;
}

.location span, .location a, .location p {
    margin-bottom: 0;
    display: block;
}

a.directions {
    padding: 5px 10px;
    background: #F68511;
    font-weight:700;
    color: black;
    text-decoration: none;
    border-radius: 10px;
    margin-top: 20px;
    display: inline;
    text-align: center;
}

svg.social {
    margin-left: 10px;
}

svg.social path {
    fill: #F68511;
}
.menu-accordion {
    cursor:default;
}

@media only screen and (max-height: 768px) {
    .menu-accordion {
        height: 0;
        cursor:pointer;
    }
    .menu-accordion.active {
        height: unset;
    }

    .menu-accordion .subitem {
        display: none;
        overflow: hidden;
        transition: max-height 0.4s ease-out;
    }
    .menu-accordion.active .subitem {
        display: block;
    }
    .accordion-toggle::after {
        content: ' \25BC'; /* Down arrow */
        float: right;
        transition: transform 0.3s;
    }
    .menu-accordion.active .accordion-toggle::after {
        transform: rotate(180deg); /* Rotate to point up */
    }
}
