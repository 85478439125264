
.three-col {
    display:flex;
    flex-direction: column;
    gap: 0;
    padding: 0 40px;
}

.swiper-padding {
    padding: 50px 50px 0;
}

.textArea {
    font-size: 16px;
    font-family: "Roboto Slab", sans-serif;
    padding: 10px;
    display: inline-block;
    max-width: 100%;
    border: none;
    margin: 0;
    border-left: 2px dashed #abafff;
    background: #a1a2c313;
    line-height: 1.2em;
    box-sizing: border-box;
    white-space: pre-line;
    outline-color: #4046ca;
}

.h1_input {
    width: 100%;
    font-size: 30px;
    padding: 10px;
    font-weight: 900;
    margin: 20px 0;
    border:none;
    display:block;
    border-left: 2px dashed #abafff;
    background: #a1a2c313;
    outline-color: #4046ca;
}



@media only screen and (min-width: 500px) {
    .single-col,.three-col {
        padding: 40px;
    } 
    .swiper-padding {
        padding: 0 40px;
    }
} 
@media only screen and (min-width: 1000px) {
    .three-col {
        gap: 50px;
        flex-direction: row;
    }
} 


@media only screen and (min-width: 1200px) {
    .three-col,.single-col {
        padding: 50px 50px 50px 90px;
    }
    .swiper-padding {
        padding: 0 40px;
    }
    .three-col>div {
        flex-basis:100%;
    }

} 



.section {
    position: relative;
    flex: 1;
}
.overlayBtn {
    position: absolute;
    left: -20px;
    right: -20px;
    top: -20px;
    bottom: -20px;
    background: transparent;
    border-radius: 10px;
    border: none;
    cursor: url("/src/edit.svg"), auto;
}    
.overlayBtn:hover {
    border: 2px dashed #4046ca;
/*     background:#4046ca1b; */
}

.hoverBtn {
    margin: -70px 10px 0 0;
    padding: 10px 20px;
    background: #4046ca;
    border:none;
    color:white;
    font-family: "Roboto Slab", sans-serif;
    border-radius: 25px; 
    text-transform: uppercase;
    font-weight: 900;
}
.hoverBtn:nth-child(2) {
    right: 190px;
    background:#f68511;
}

h2 {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 900;
    margin: 0 0 15px;
    border-radius: 5px;
    display: inline-block;
}
h3 {
    margin: 10px 0 5px;
}

p {
    margin-bottom: 10px;
}

.section img {
    max-width: 750px;
    width: 100%;
    text-align: center;
}

.part {
    margin-top: 20px;
}