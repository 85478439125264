.eust-title {
    margin: 100px 0 20px 0;
    font-size: 32px;
    padding: 20px 90px 0 30px;
}
.wave_link {
    line-height: 25px;
    display: inline-block;
    color: black;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    gap: 10px;
    text-decoration: none;
}

.wave_link:hover {
    text-decoration: underline;
    transform: scale(1.05);
    transform-origin: 0 0;
}
.wave_link:hover span {
    text-decoration: none;
}

.wave_link strong {
    display: inline-block;
    width: 100%;
}

.wave_link span:first-child {
    font-weight: bold;
    background: black;
    color: white;
    padding: 0 7px;
    display: inline-block;
    border-radius: 10px;    
}

#publications {
    margin: 100px 30px;
}
.wave_selectors h3 {
    vertical-align: top;
    display: inline-block;
    margin-right: -2px;
    padding: 2px 10px;
    border-radius: 10px;
    margin: 5px 0;
    color: black;
    border: 2px solid black;
}
.wave section>p {
    margin: 30px 0 0;
    gap: 5px;
    columns:3;
    column-width: 360px;
    flex-wrap: wrap;
    column-gap: 20px;
}
.wave section>p:nth-child(1) {
    font-weight: bold;
    margin-bottom: 10px;
}


.wave {
    margin-top: 0;
}

.wave section p a {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid-column;
    margin-bottom: 10px;
}
.eust-intro{
    display: flex;
    flex-wrap:wrap;
    gap: 0 40px;
    max-width: 1440px;
    margin: 0 auto;
}
.eust-intro>div {
    flex-basis: 100%;
    margin-top: 20px;
}

.eust-links {
    display: flex;
    width:fit-content;
    min-width: 400px;
}
.eust-links h2 {
    display: flex;
}


.eust-links p:nth-child(2),
.eust-links p:nth-child(3),
.eust-links p:nth-child(4),
.eust-links p:nth-child(5) {
    display: inline-flex;
    gap: 10px 20px;
    font-weight: bold;
    align-items: baseline;
    max-width: 140px;
    text-transform: uppercase;
    margin-right: 20px;
    vertical-align: middle;
}
.eust-links p:nth-child(2) a,
.eust-links p:nth-child(3) a,
.eust-links p:nth-child(4) a,
.eust-links p:nth-child(5) a {
    font-weight:bold;
    color: black;
    text-decoration: none;
}
.eust-links p a img {
    height: 30px;
    width:unset;
    display:block;
}
.eust-links p a:focus,
.eust-links p a:hover {
    transform:scale(1.05);
}
.eust-links p {
    margin-bottom: 20px;
}

.consortium>div {
    display: flex;
    gap: 20px;
    align-items: baseline;
    flex-wrap: wrap;
}
.consortium>div p {
    max-width: 180px;
    text-align: center;
}
.consortium>div p:nth-child(1),
.consortium>div p:nth-child(2) {
    margin-top:0;
    max-width: unset;
    width: 100%;
    text-align: left;
}


.eust-intro .full_width {
    columns: 1;
    column-width: unset;
}

#publications .slick-slide {
    padding: 10px;
}

#publications .slick-slider {
    display: flex;
    flex-direction:column-reverse;
}

#publications .slick-slider .slick-prev {
    left: -20px;
}
#publications .slick-slider .slick-next {
    right: -20px;
}

.wave_selectors {
    list-style: none;
}
.wave_selectors li {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 0;
}

#publications .slick-active h3 {
    color: white;
    background: black;
}


.extras {
    padding: 20px 50px 100px 30px;
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1720px;
    margin: 0 auto;
}
.eust-logod p {
    display: flex;
    flex-wrap:wrap;
    gap: 20px;
    max-width: 1000px;
    align-items: center;
}
.eust-logod img{
    max-width: 150px;
    object-fit: contain;
    width: unset;
    height: unset;
}

@media only screen and (min-width: 840px){
    .eust-intro {
        flex-wrap: nowrap;
    }
    .eust-intro .part {
        flex-basis: 50%;
    }
    .multicolumn {
        width: 200%
    }
}
@media only screen and (min-width: 1200px){
    .eust-intro {
        padding: 20px 90px 0 140px;
    }
    .eust-title {
        font-size: 40px;
        padding: 20px 90px 0 140px;
        max-width: 1720px;
        margin: 100px auto 0;
    }
    #publications {
        margin: 100px 50px 50px 140px;
    }

    .extras {
        padding: 20px 90px 100px 140px;
    }

}

@media only screen and (min-width: 1600px){
    .eust-intro {
        padding: 0;
    }
    #publications {
        margin: 50px auto 0;
        max-width: 1440px;
    }
    .eust-logod {
        margin: 50px auto;
        max-width: 1440px;
    }

}

