.icon_btn {
    cursor:pointer;
    margin: 5px;
    display:flex;
    gap: 10px;
    align-items: center;
    max-width: 200px;
}
.btns {
    display: flex;
    flex-direction: column;
    gap: 20px;
} 
.icon_btn img {
    max-width:40px;
}

.icon_btn span {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 12px;
}

@media only screen and (min-width: 720px) and (min-height: 720px) {
    .icon_btn span {
        display: none;
    }
    .btns {
        gap:0;
    }
}