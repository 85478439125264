.projects-container {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.projectlist {
    padding-left: 20px;
}

.project_back {
    height: 16px;
    width: 16px;
    display:block;
    margin: 0px 0 20px;
}

.projects {
    padding-left: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items:flex-start;
    box-sizing: border-box;
    list-style: none;
    gap: 40px 30px;
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: flex-start;
    transition:0.5s;
}
.projectlink  {
    width: 100%;
    height: 100%;
    position:absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content:""
}

.projects .collapsed .back {
    display:none;
}

.projects>li {
    text-align: right;
    transition: 0.5s;
    position: relative;
    width: 50%;
}

.more_projects {
    padding: 10px 20px;
    background:white;
    border:2px solid black;
    font-family: "Roboto Slab", sans-serif;
    border-radius: 25px; 
    text-transform: uppercase;
    font-weight: 900;
}

.move_forward {
    margin: 10px 10px 0 0;
    display: flex;
    gap: 20px;
    align-items: center;
}

.move_forward p {
    margin: 0;
}

.tabs h3 {
    text-align: left;
}
.tabs .open_tab {
    position: relative;
    padding: 20px;
}
.tabs .open_tab.subproject {
    position: relative;
    padding: 30px 30px 190px;
    margin-bottom: -190px;
}
.projects li:not(.collapsed) .subproject .years {
    top: -17px;
    bottom:unset;
    font-weight: bold;
}
.subproject,
.project {
    transition: 0.5s;
    min-height: 220px;
    border: 2px solid black;
    border-radius: 10px;
    padding: 30px 20px 20px 40px;   
    background: white;
    position:relative;
    width: 100%;
}

.lang_missing {
    position: absolute;
    left: 15px;
    bottom: -7px;
    justify-content: center;
    font-weight: bold;
    padding: 0;
    transform-origin: 0 0;
    line-height:14px;
}

.subproject {
    padding: 20px;   
    position: absolute;
    height: 100%;
    width: 100%;
}

.subproject .people,
.subproject .categories,
.open_tab .people,
.project.minimized .people {
    display: none;
}

.subproject h3 {
    margin: 0;
    }
    
.projects li {
    flex: 0 0 700px;
    max-width: calc(100% + 30px);
    margin-left: -30px;
}
.projects li.collapsed {
    flex: 0 0 300px;
    transition: 0.5s;
    max-width: calc(100% - 20px);
    margin-left: 0;
}

@media screen and (min-width:450px) {
    .projects li {
        max-width: calc(100% - 5px);
        margin-left: 0px;
    }
}

@media screen and (min-width:720px) {
    .projects li.collapsed {
        flex: 0 0 265px;
        max-width: 300px;
    }
    .tabs .open_tab {
        padding: 30px;
    }
}

@media screen and (min-width:1200px) {
    .projects li {
        flex: 0 0 1200px;
        max-width: 100%;
        margin-left: 0;
    }
}



.project{
    font-weight: 700;
    text-decoration: none;
    color: black;
}

.minimized .formattedText,
.minimized .failid {
    display: none;
}

.projects li h2 {
    text-align: left;
    text-transform: none;
}

.projects .collapsed h2 {
    font-size: 16px;
    font-weight:700;
    text-align: right;
}

.people {
    position: absolute;
    left: -20px;
    top: 30px;
    width: 40px;
}

.categories {
    position: absolute;
    top: -20px;
    right: 20px;
}
.category {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    z-index: 1;
    margin-left: -16px;
    object-fit: cover;
}

.number-circle,
.avatar {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    border: 2px solid black;
    z-index: 1;
    margin-top: -16px;
    object-fit: cover;
}

.avatar.no_border {
    border: 0.5px solid black;
}
.number-circle {
    text-align: center;
    display: inline-block;
    line-height:38px;
    background: rgb(255, 255, 255);
}
.years {
    position: absolute;
    bottom: -25px;
    padding: 5px;
    right: 20px;
    background: white;
}

.dotted {
    border-color:white;
    background: #eee;
}

.dotted .years {
    background:none;
}

.partner {
    font-weight: bold;
}

.singleproject h2 {
    text-transform: none;
}

.singleproject {
    font-weight: normal;
    text-align: left;
    display:block;
}
.singleproject ul {
    margin: 10px 10px 10px 20px;
}
.singleproject ul li {
    margin-top: 10px;
}
.singleproject ol li{
    margin-left: 20px;
}
.singleproject ol li::marker {
    font-weight: bold;
}
.singleproject h3{
    margin-top: 50px;
}

.project_authors {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 15px;
    padding: 20px 0;
}

.project_authors a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    gap: 5px;
    text-decoration: underline;
    color: black;
}

.project_authors a img {
    height: 40px;
    width: 40px;
    display: inline-block;

}

.project_authors a span {
    display: inline-block;

}
.project_authors span {
font-weight: bold;
    
}



.project_categories {
    display: flex;
    flex-wrap: wrap;
    gap:0 20px;
    padding: 10px 5px 20px;
}

.category_link {
    display:inline-flex;
    gap: 10px;
    padding-left: 10px;
    justify-content: center;
    align-items: center;
    color: black;
}
.category_link img {
    width: 20px;
    height: 20px;
}
.category_link span {
    font-weight: bold;
    font-size: 16px;
}

.failid a {
    display: inline-block;;
    color: black;
    margin-top: 10px;
    line-height: 20px;;
    margin-right: 50px;
}

.failid span {
    text-decoration: none;
    margin-right:5px;
    display: inline-block;
    padding: 5px 8px;
    background-color: black;
    color: white;
    font-weight: bold;
    border-radius: 10px;;
}
