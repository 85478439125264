  * {
    font-family: "Roboto Slab", sans-serif;
    padding: 0;
    margin: 0;
  }
  .graph{
    margin-left:5px;
    transform:translateY(30px)
  }
  .timeline {
    margin-left: 10px;
  }

  .connecting_line {
    transition: 0.5s;
    position: absolute;  
    display: inline-block; 
  }


  .duration_line {
    border-radius: 2px;
    width: 8px; 
    transition: 0.5s;
    background: #a5a5a5;
    opacity: 0.7;
    position: absolute;
    left: 11px;   
    display: inline-block; 
  }

  .vertical-multi-range {
    position: relative;
    width: 40px; 
    height: 395px;    
  }
  
  .column{
    display:inline-block;
    margin-right: 0.4px;
    width: 10px;
  } 
  
  .dot{
    display: block;
    margin: 0;
    margin-top: 4px;
    height:10px;  
    width:10px;
    border-radius:6px;
    background:black;
  }

  .vertical-tick {
    width: 16px;
    height: 3px;
    border-radius: 3px;
    background: black;
    position: absolute;
    top: 7px;
  }

input[type=range] {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  appearance: none;
  overflow: hidden;
  writing-mode: vertical-lr;
  background: transparent;
  position: absolute;
  margin: 0;
  border: 0;
  border-radius: 1px;
  padding: 0 5px;
  background-size: 2px 100%;
  z-index:1;
  left: 5px;
  top: 2px;
  width: 20px; 
}
input[type=range]:active,
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-thumb {
  height: 16px;
  width: 16px;
  border-radius: 28px;
  -webkit-appearance: none;
  background-color: black;
  position: relative;
  margin: 0 7px;
  cursor: pointer;
  pointer-events: all;
}
.smaller[type=range]::-webkit-slider-thumb {
  background:transparent;
}


.multi-range input[type=range] {position: absolute;}
.multi-range input[type=range]:nth-child(2) {background: none;}
.multi-range input[type=range]:nth-child(1)::-webkit-slider-thumb::before {background-color: red;}
.multi-range input[type=range]:nth-child(2)::-webkit-slider-thumb::before {background-color: grey;}

.number {
  left: 30px;
  position: absolute;
  transform: translatey(-50%);
  font-weight:bold;
}
.range_line {
  background: rgb(191, 191, 191);
  height: 4px;
  border-radius: 2px;
  position: absolute;
  z-index: -1;
}

@media only screen and (min-width: 720px) {


}

